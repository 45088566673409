import { createRouter,  createWebHashHistory } from 'vue-router'
import LayoutComponent from '../components/LayoutComponent.vue';
import PaymentPage from '../components/PaymentPage.vue';

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: LayoutComponent,
    children: [
      {
        path: 'i/:hash',
        name: 'Invitation',
        component: PaymentPage
      }
    ]
  }
]

const router = createRouter({
  "history": createWebHashHistory(),
  routes
})

export default router
