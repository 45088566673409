<template>
      <router-view />
</template>
<style>
:root {
  --normal: "Helvetica Neue";
  --italic: "Helvetica Neue Medium";
  --medium: "Helvetica Neue Medium";
  --medium-italic: "Helvetica Neue Medium Italic";
}

@font-face {
  font-family: "Helvetica Neue";
  src: url(assets/fonts/HelveticaNeueCyr-Roman.otf);
}
@font-face {
  font-family: "Helvetica Neue Italic";
  src: url(assets/fonts/HelveticaNeueCyr-Italic.otf);
}
@font-face {
  font-family: "Helvetica Neue Medium";
  src: url(assets/fonts/HelveticaNeueCyr-Medium.otf);
}
@font-face {
  font-family: "Helvetica Neue Medium Italic";
  src: url(assets/fonts/HelveticaNeueCyr-MediumItaicl.otf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
}

a:hover,
button:hover {
  opacity: 0.7;
  cursor: pointer;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

</style>
